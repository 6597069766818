import React from "react"

export default function hero({
    image,
    title,
    heading,
    text,
    children,
    gradient,
}) {
    return (
        <section className={`pt-10 ${gradient}`}>
            <div className="container flex flex-col-reverse items-center md:flex-row md:space-x-20">
                <div className="pt-10 md:w-1/2">
                    <img className="w-full h-auto" src={image} alt="Hero" />
                </div>
                <div className="space-y-5 md:w-1/2">
                    <h1 className="text-4xl font-bold text-center text-white md:text-6xl md:text-left">
                        {title}
                    </h1>
                    <h2 className="text-lg font-bold text-gray-800 md:text-xl">
                        {heading}
                    </h2>
                    <p className="text-lg text-gray-800 md:text-xl">{text}</p>
                    {children}
                </div>
            </div>
        </section>
    )
}
