import * as React from "react"
import Features from "../components/Features"
import Hero from "../components/Hero"
import Partners from "../components/Partners"
import Reviews from "../components/Reviews"
import Seo from "../components/SEO"
import Mailchimp from "../components/Mail/Mailchimp"

import heroImg from "../images/hero.png"

const IndexPage = () => (
    <>
        <Seo title="Devs and DevOps want simplicity, too." />
        <Hero
            image={heroImg}
            gradient="bg-gradient-to-tr from-blue-300 to-blue-500"
            title="Devs and DevOps want simplicity, too."
            heading="All your virtual servers on premises. In your own private cloud."
            text="DESTROY the long set up time for your environments. Go from hours to seconds. See how easy it is and get an introductory deal."
        >
            {/* <Mailchimp
                onlyEmail
                className="flex space-x-3 md:w-2/3"
                btnText="Get the Invite"
                btnClassName="!bg-[#ED9425] hover:!bg-[#FFAB42]"
            /> */}
            <a href="#" className="btn">
                Start Configuring Now
            </a>
        </Hero>
        <Partners />
        <Reviews />
        <Features />
    </>
)

export default IndexPage
